
import MailIcon from '@/views/SignIn/MailIcon.vue';
import LockIcon from '@/views/SignIn/LockIcon.vue';
import PassHiddenIcon from '@/views/SignIn/PassHiddenIcon.vue';
import PassVisibleIcon from '@/views/SignIn/PassVisibleIcon.vue';
import { defineComponent, ref } from 'vue';
import Logo from './Logo.vue';
import CheckboxDark from './CheckboxDark/index.vue';
import partnerAPI from '@/service/partnerAPI';
import axios from 'axios';
import { useRouter } from 'vue-router';
import storageKey from '@/constants/storageKey';
import localStorage from '@/utils/localStorage';
import isValidEmail from '@/utils/isValidEmail';

/**
 * 로그인
 */
export default defineComponent({
  name: 'SignIn',
  components: {
    Logo,
    MailIcon,
    LockIcon,
    PassHiddenIcon,
    PassVisibleIcon,
    CheckboxDark,
  },
  setup() {
    const classes = {
      formInputWrap: 'h-15 border-b border-gray-400 flex items-center',
      formInput: 'flex-1 bg-transparent focus:outline-none px-4',
      commonButton:
        'h-10 w-full text-sm flex justify-center items-center text-center border border-gray-700',
    };

    const router = useRouter();
    const email = ref('');
    const password = ref('');
    const isPasswordVisible = ref(false);
    const isKeepId = ref();
    const isEmailError = ref(false);

    const checkIsEmailValid = () => {
      console.log('email.value', email.value);

      isEmailError.value = !isValidEmail(email.value);
      console.log('check', isEmailError.value);
    };

    const handleLogin = () => {
      checkIsEmailValid();

      partnerAPI.partnerAuth
        .loginUsingPOST2({
          authParamDTO: {
            email: email.value,
            password: password.value,
          },
        })
        .then(({ data }) => {
          const authToken = `Bearer ${(data as any).data.token}`;

          axios.defaults.headers.common['Authorization'] = authToken;
          localStorage.set(storageKey.AUTH_TOKEN, authToken);
          localStorage.set(storageKey.PARTNER_INFO, (data as any).data);

          if (isKeepId.value) {
            localStorage.set(storageKey.SIGN_IN_EMAIL, email.value);
          }

          router.push('/app');
        })
        .catch((e) => {
          alert(e?.response?.data?.message || '로그인에 실패했습니다.');
        });
    };

    const togglePasswordVisible = () => {
      isPasswordVisible.value = !isPasswordVisible.value;
    };

    return {
      handleLogin,
      classes,
      isPasswordVisible,
      email,
      password,
      togglePasswordVisible,
      isKeepId,
      isEmailError,
      checkIsEmailValid,
    };
  },
});
