<template>
  <div class="SignIn w-screen h-screen inset-0 text-white relative">
    <div class="absolute inset-0 z-10 flex">
      <div class="SignIn__blackBG flex-1"></div>
      <div class="SignIn__imageBG"></div>
    </div>

    <div class="Signup__container">
      <div class="absolute inset-0 z-20 px-8">
        <div class="Signup__containerDivider"></div>

        <div class="mt-25 flex justify-center">
          <Logo></Logo>
        </div>

        <div class="mt-16">
          <div>
            <!-- login form -->

            <h1
              class="text-primary"
              style="
                font-size: 32px;
                font-family: Futura;
                letter-spacing: 0.1em;
              "
            >
              PARTNER <span class="font-bold">CENTER</span>
            </h1>
            <div class="text-xs">
              지금 <span class="text-primary"><a href="/auth/signup/">블랙랏 파트너</a></span>에 가입하고,
              사업자를 위한 다양한 서비스와 풍성한 혜택을 누려보세요!
            </div>

            <div class="SignIn__formWrap text-gray-300">
              <form @submit.prevent="handleLogin" class="SignIn__form mt-15">
                <div :class="classes.formInputWrap">
                  <MailIcon></MailIcon>
                  <input
                    name="id"
                    id="id"
                    autocomplete="id"
                    v-model="email"
                    :class="classes.formInput"
                    @blur="checkIsEmailValid"
                  />
                  <div v-if="isEmailError" class="text-error text-sm">
                    잘못된 이메일주소입니다.
                  </div>
                </div>
                <div :class="[classes.formInputWrap, 'mt-4']">
                  <LockIcon></LockIcon>
                  <input
                    name="pw"
                    id="pw"
                    autocomplete="pw"
                    v-model="password"
                    :class="classes.formInput"
                    :type="isPasswordVisible ? 'text' : 'password'"
                  />
                  <button type="button" @click="togglePasswordVisible">
                    <span v-if="isPasswordVisible">
                      <PassVisibleIcon></PassVisibleIcon>
                    </span>
                    <span v-else><PassHiddenIcon></PassHiddenIcon> </span>
                  </button>
                </div>
                <div class="mt-8">
                  <!-- :checked="isKeepId" -->
                  <CheckboxDark
                    className="Signup__checkbox"
                    label="아이디 저장"
                    name="keepEmail"
                    v-model:checked="isKeepId"
                  ></CheckboxDark>
                </div>
                <button
                  class="w-full h-12 mt-12 bg-gray-50 text-black"
                  type="submit"
                >
                  로그인
                </button>
              </form>

              <div class="w-full mt-6 grid grid-cols-2 gap-0">
                <div class="border-r pr-4 text-right">
                  <router-link to="/auth/signup/">파트너 가입</router-link>
                </div>
                <div class="pl-4 text-left">
                  <!-- TODO: -->
                  <button>아이디 ・ 비밀번호 찾기</button>
                </div>
              </div>

              <div class="mt-18">
                <div class="grid grid-flow-row grid-cols-2 gap-4">
                  <div>
                    <!-- TODO: -->
                    <a
                      href="https://blacklot.com/terms"
                      target="_blank"
                      :class="classes.commonButton"
                      >이용약관</a>
                  </div>
                  <div>
                    <!-- TODO: -->
                    <a
                      href="https://blacklot.com/privacy"
                      target="_blank"
                      :class="classes.commonButton"
                    >
                      개인정보처리방침
                    </a>
                  </div>
                </div>
              </div>

              <div class="text-xs mt-8">
                <div>
                  (주)서울옥션 (대표이사 이옥경) | 사업자등록번호 101-81-46818 |
                  통신판매업신고번호 종로 제01-2173호 ㅣ 서울특별시 종로구
                  평창30길 24 (평창동 98번지) 파트너센터 02-2075-4385 평일 9시~18시
                  (12~13시 점심시간 제외, 토/일/공휴일 휴무)
                </div>
                <br />
                <div>
                  ㈜서울옥션이 매도인인 경우를 제외한 모든 온라인 경매 출품
                  물품들에 대하여 ㈜서울옥션은 통신판매중개자이며 거래 당사자가
                  아닙니다. 따라서 경매 물품에 대한 매매계약은 위탁자와 낙찰자
                  사이에 성립하며, ㈜서울옥션은 온라인 경매 약관이 정하는 범위
                  내에서만 책임을 부담합니다
                </div>
                <div>COPYRIGHT@SEOUL AUCTION INC. ALL RIGHTS RESERVED</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import MailIcon from '@/views/SignIn/MailIcon.vue';
import LockIcon from '@/views/SignIn/LockIcon.vue';
import PassHiddenIcon from '@/views/SignIn/PassHiddenIcon.vue';
import PassVisibleIcon from '@/views/SignIn/PassVisibleIcon.vue';
import { defineComponent, ref } from 'vue';
import Logo from './Logo.vue';
import CheckboxDark from './CheckboxDark/index.vue';
import partnerAPI from '@/service/partnerAPI';
import axios from 'axios';
import { useRouter } from 'vue-router';
import storageKey from '@/constants/storageKey';
import localStorage from '@/utils/localStorage';
import isValidEmail from '@/utils/isValidEmail';

/**
 * 로그인
 */
export default defineComponent({
  name: 'SignIn',
  components: {
    Logo,
    MailIcon,
    LockIcon,
    PassHiddenIcon,
    PassVisibleIcon,
    CheckboxDark,
  },
  setup() {
    const classes = {
      formInputWrap: 'h-15 border-b border-gray-400 flex items-center',
      formInput: 'flex-1 bg-transparent focus:outline-none px-4',
      commonButton:
        'h-10 w-full text-sm flex justify-center items-center text-center border border-gray-700',
    };

    const router = useRouter();
    const email = ref('');
    const password = ref('');
    const isPasswordVisible = ref(false);
    const isKeepId = ref();
    const isEmailError = ref(false);

    const checkIsEmailValid = () => {
      console.log('email.value', email.value);

      isEmailError.value = !isValidEmail(email.value);
      console.log('check', isEmailError.value);
    };

    const handleLogin = () => {
      checkIsEmailValid();

      partnerAPI.partnerAuth
        .loginUsingPOST2({
          authParamDTO: {
            email: email.value,
            password: password.value,
          },
        })
        .then(({ data }) => {
          const authToken = `Bearer ${(data as any).data.token}`;

          axios.defaults.headers.common['Authorization'] = authToken;
          localStorage.set(storageKey.AUTH_TOKEN, authToken);
          localStorage.set(storageKey.PARTNER_INFO, (data as any).data);

          if (isKeepId.value) {
            localStorage.set(storageKey.SIGN_IN_EMAIL, email.value);
          }

          router.push('/app');
        })
        .catch((e) => {
          alert(e?.response?.data?.message || '로그인에 실패했습니다.');
        });
    };

    const togglePasswordVisible = () => {
      isPasswordVisible.value = !isPasswordVisible.value;
    };

    return {
      handleLogin,
      classes,
      isPasswordVisible,
      email,
      password,
      togglePasswordVisible,
      isKeepId,
      isEmailError,
      checkIsEmailValid,
    };
  },
});
</script>

<style lang="scss" scoped>
.SignIn__blackBG {
  background: #181818;
}

.SignIn__imageBG {
  width: 70vh;
  height: 100vh;
  background-image: url('/images/signin_bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #181818;
}

.Signup__container {
  position: relative;
  width: 1664px;
  margin-left: auto;
  margin-right: auto;
  @media all and (max-width: 1665px) {
    width: 100%;
  }
}

.Signup__containerDivider {
  position: absolute;
  width: 1px;
  background: #333;
  height: calc(100vh - 184px);
  top: 184px;
  left: 50%;
  transform: translateX(-50%);
}

.SignIn__formWrap {
  width: 496px;
  margin-top: 3.875rem;
}

.SignIn__form {
  margin-top: 3.875rem;
}

.Signup__checkbox {
  color: #999;
}
.SignIn,
.SignIn__imageBG {
  @media all and (max-height: 1000px) {
    height: calc(16.5em + 814px - 56px);
  }
}
</style>
